export default function ArialTargets() {
	let targets = document.querySelectorAll('.arial-targets li');

	//targets.forEach((target) => {
	for (const target of Array.from(targets)) {
		target.addEventListener('click', (e) => {
			let currentTarget = e.currentTarget

			//targets.forEach((target) => {
			for (const target of Array.from(targets)) {
				if ( target !== currentTarget ) {
					target.classList.remove('selected')
				}
			}

			currentTarget.classList.toggle('selected')
		});
	};
}
