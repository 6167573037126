export default function ToggleManager() {
	let closes = document.querySelectorAll('[data-toggle-close]');

	//closes.forEach((close) => {
	for (const close of Array.from(closes)) {
		close.addEventListener('click', (e) => {
			let close = e.currentTarget.dataset.toggleClose.split(',');
			if ( close === '' ) { /* exit */ }

			if ( close.includes('all') ) {
				//document.body.classList.forEach((classname) => {
				for (const classname of Array.from(document.body.classList)) {
					if ( classname.startsWith('toggle-') ) {
						document.body.classList.remove(classname);
					}
				};
			} else {
				//close.forEach((classname) => {
				for (const classname of Array.from(close)) {
					document.body.classList.remove('toggle-' + classname);
				};
			}
		});
	};

	let opens = document.querySelectorAll('[data-toggle-open]');

	//opens.forEach((open) => {
	for (const open of Array.from(opens)) {
		open.addEventListener('click', (e) => {
			let open = e.currentTarget.dataset.toggleOpen.split(',');
			if ( open === '' ) { /* exit */ }

			//open.forEach((classname) => {
			for (const classname of Array.from(open)) {
				document.body.classList.toggle('toggle-' + classname);
			};
		});
	};
}
