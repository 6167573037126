import React from "react"

/* OLD FORMASSEMBLY FORM
const Form = () => (
	<>
		<iframe src="https://tfaforms.com/4805816" frameBorder="0" title="Register" height="200"></iframe>
		<iframe src="https://metropolis-agency.formstack.com/forms/flow_residences" title="Flow Residences" height="460"></iframe>
		<script src="//tfaforms.com/js/iframe_resize_helper.js"></script>
	</>
)
*/

const Form = () => (
	<>
		<div className="registration-form"><div>
			<iframe src="https://forms.metropolis.com.au/flow-residences/" title="Flow Residences" height="460"></iframe>
		</div></div>
	</>
)

export default Form