import React from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import { globalHistory as history } from '@reach/router'

import RegisterPanel from "./register-panel"

import headerSceneImageHome from "../../images/header-home.jpg"
import headerSceneImageLocation from "../../images/header-location.jpg"
import headerSceneImageResidences from "../../images/header-residences.jpg"
import headerSceneImageResidencesClub from "../../images/header-residences-club.jpg"
import headerSceneImageTeam from "../../images/header-team.jpg"
import headerSceneImageRegister from "../../images/header-register.jpg"

class Header extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props)

    this.state = {
      pageTitle: 'Flow Residences',
      loading: '1'
    };
  }

  componentDidMount() {
    this._isMounted = true;

    let { location } = history

    switch(location.pathname) {
       case '/location/': {
          this.setState({ pageTitle: 'Location' });
          break;
       }
       case '/residences/': {
          this.setState({ pageTitle: 'Residences' });
          break;
       }
       case '/residences-club/': {
          this.setState({ pageTitle: 'Residences Club Level' });
          break;
       }
       case '/team/': {
          this.setState({ pageTitle: 'Team' });
          break;
       }
       default: {
          this.setState({ pageTitle: 'Flow Residences' });
          break;
       }
    }

    this.setState({ loading: '0' });

    if ( document.body.classList.contains('toggle-menu') ) {
      setTimeout(() => {
        document.body.classList.remove('toggle-menu')
      }, 1000)
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  exitPage = (exit, node) => {    
    let { location } = history
    
    if ( location.pathname ) {
      let el = document.querySelector('#header nav a[href="' + location.pathname + '"]')

      if ( el ) {
        
        document.querySelector('.header-menu--image [data-id="' + el.dataset.id + '"]').classList.add('lock')
        //el_nav.setAttribute('aria-current', 'page')
      }
    }

    if (this._isMounted) {
      this.setState({ loading: '1' });
    }
  }

  entryPage = (entry, node) => { 
    //document.body.classList.remove('toggle-menu');

    if (this._isMounted) {
      this.setState({ loading: '0' });
    }
  }

  linkClick(e) {
    if ( e.currentTarget.getAttribute('aria-current') === "page" ) {
      document.body.classList.remove('toggle-menu');
    }
  }

  linkEnter(e) {
    let id = e.currentTarget.dataset.id;

    if ( id ) {
      let el = document.querySelector('.header-menu--image [data-id="' + id + '"]')

      if ( el ) {
        //document.querySelectorAll('.header-menu--image [data-id]').forEach((e) => {
        for (const e of Array.from(document.querySelectorAll('.header-menu--image [data-id]'))) {
          e.classList.remove('active')
        };

        el.classList.add('active')
      }
    }
  }

  linkLeave(e) {
    //document.querySelectorAll('.header-menu--image [data-id]').forEach((e) => {
    for (const e of Array.from(document.querySelectorAll('.header-menu--image [data-id]'))) {
      e.classList.remove('active')
    };

    let { location } = history
    
    if ( location.pathname ) {
      let el = document.querySelector('#header nav a[href="' + location.pathname + '"]')

      if ( el ) {

        document.querySelector('.header-menu--image [data-id="' + el.dataset.id + '"]').classList.add('active')
        //el_nav.setAttribute('aria-current', 'page')
      }
    }
  }

  render() {
    let { location } = history

    if ( location.pathname ) {
      let el_nav = document.querySelector('#header nav a[href="' + location.pathname + '"]')

      if ( el_nav ) {
        document.querySelector('.header-menu--image [data-id="' + el_nav.dataset.id + '"]').classList.add('active')
      }
    }


    return (
      <header id="header">
        
        <div id="menu-panel" className="header-menu">
          <div className="header-menu--image">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 434 569">
              <defs>
                  <clipPath id="menu-clippath">
                      <path d="M.137,216.862V568.511H433.77V216.862C433.77,97.118,336.7.046,216.953.046S.137,97.118.137,216.862" style={{fill: 'none'}} />
                  </clipPath>
              </defs>
              
              <g style={{clipPath: 'url(#menu-clippath)'}}>
                  <image data-id="1" className="img" width="1726" height="2243" transform="translate(-0.917 -3.363) rotate(0.5) scale(0.255)" xlinkHref={headerSceneImageHome} />
                  <image data-id="2" className="img" width="1726" height="2243" transform="translate(-0.917 -3.363) rotate(0.5) scale(0.255)" xlinkHref={headerSceneImageLocation} />
                  <image data-id="3" className="img" width="1726" height="2243" transform="translate(-0.917 -3.363) rotate(0.5) scale(0.255)" xlinkHref={headerSceneImageResidences} />
                  <image data-id="4" className="img" width="1726" height="2243" transform="translate(-0.917 -3.363) rotate(0.5) scale(0.255)" xlinkHref={headerSceneImageResidencesClub} />
                  <image data-id="5" className="img" width="1726" height="2243" transform="translate(-0.917 -3.363) rotate(0.5) scale(0.255)" xlinkHref={headerSceneImageTeam} />
                  <image data-id="6" className="img" width="1726" height="2243" transform="translate(-0.917 -3.363) rotate(0.5) scale(0.255)" xlinkHref={headerSceneImageRegister} />
              </g>
            </svg>
            <div className="anim"></div>
          </div>

          <nav>
            <ul>
              <li>
                <TransitionLink 
                  to="/" 
                  data-toggle-close="register" 
                  data-id="1" 
                  exit={{
                    length: 1,
                    trigger: ({ exit, node }) => this.exitPage(exit, node),
                    state: { test: 'exit state' },
                  }}
                  entry={{
                    delay: 1,
                    trigger: ({ entry, node }) => this.entryPage(entry, node),
                    state: { test: 'entry state' },
                  }}
                  onMouseEnter={this.linkEnter} 
                  onMouseLeave={this.linkLeave}
                  onClick={this.linkClick}
                >
                  <span><span>Home</span></span>
                </TransitionLink>
              </li>
              <li>
                <TransitionLink 
                  to="/location/" 
                  data-toggle-close="register" 
                  data-id="2" 
                  exit={{
                    length: 1,
                    trigger: ({ exit, node }) => this.exitPage(exit, node),
                    state: { test: 'exit state' },
                  }}
                  entry={{
                    delay: 1,
                    trigger: ({ entry, node }) => this.entryPage(entry, node),
                    state: { test: 'entry state' },
                  }}
                  onMouseEnter={this.linkEnter} 
                  onMouseLeave={this.linkLeave}
                  onClick={this.linkClick}
                >
                  <span><span>Location</span></span>
                </TransitionLink>
              </li>
              <li>
                <TransitionLink 
                  to="/residences/" 
                  data-toggle-close="register" 
                  data-id="3" 
                  exit={{
                    length: 1,
                    trigger: ({ exit, node }) => this.exitPage(exit, node),
                    state: { test: 'exit state' },
                  }}
                  entry={{
                    delay: 1,
                    trigger: ({ entry, node }) => this.entryPage(entry, node),
                    state: { test: 'entry state' },
                  }}
                  onMouseEnter={this.linkEnter} 
                  onMouseLeave={this.linkLeave}
                  onClick={this.linkClick}
                >
                  <span><span>Residences</span></span>
                </TransitionLink>
              </li>
              <li>
                <TransitionLink 
                  to="/residences-club/"  
                  data-toggle-close="register" 
                  data-id="4" 
                  exit={{
                    length: 1,
                    trigger: ({ exit, node }) => this.exitPage(exit, node),
                    state: { test: 'exit state' },
                  }}
                  entry={{
                    delay: 1,
                    trigger: ({ entry, node }) => this.entryPage(entry, node),
                    state: { test: 'entry state' },
                  }}
                  onMouseEnter={this.linkEnter} 
                  onMouseLeave={this.linkLeave}
                  onClick={this.linkClick}
                >
                  <span><span>Residences Club Level</span></span>
                </TransitionLink>
              </li>
              <li>
                <TransitionLink 
                  to="/team/" 
                  data-toggle-close="register" 
                  data-id="5" 
                  exit={{
                    length: 1,
                    trigger: ({ exit, node }) => this.exitPage(exit, node),
                    state: { test: 'exit state' },
                  }}
                  entry={{
                    delay: 1,
                    trigger: ({ entry, node }) => this.entryPage(entry, node),
                    state: { test: 'entry state' },
                  }}
                  onMouseEnter={this.linkEnter} 
                  onMouseLeave={this.linkLeave}
                  onClick={this.linkClick}
                >
                  <span><span>Team</span></span>
                </TransitionLink>
              </li>
              <li>
                <button className="navigation--register" data-toggle-open="register" data-id="6" data-toggle-close="menu" onMouseEnter={this.linkEnter} onMouseLeave={this.linkLeave}>
                  <span><span>Register</span></span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        <RegisterPanel />

        <div className="header--bg"></div>

        <button className="header--register" data-toggle-open="register" data-toggle-close="menu">Register</button>

        <h1 className="header--title" data-loading={this.state.loading}>
          <TransitionLink 
            to="/" 
            data-toggle-close="register,menu"
            exit={{
              length: 1,
              trigger: ({ exit }) => this.exitPage(exit),
              state: { test: 'exit state' },
            }}
            entry={{
              delay: 1,
              trigger: ({ entry, node }) => this.entryPage(entry, node),
            }}
          >
            <span className="loader">Please wait...</span>
            <span className="title">{this.state.pageTitle}</span>
            <span className="mobile">Flow Residences</span>
          </TransitionLink>
        </h1>

        <button className="header--hamburger" data-toggle-open="menu" data-toggle-close="register">
          <span className="label">
            <span className="open">Menu</span>
            <span className="close">Close</span>
          </span>
          <span className="header--hamburger--icon">
            <i></i><i></i><i></i><i></i>
          </span>
        </button>
      </header>
    )
  }
}

export default Header
