/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import { TransitionPortal } from "gatsby-plugin-transition-link";

import * as ScrollMagic from "scrollmagic";
import { gsap, TweenLite, TimelineLite, Linear } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Scrollbar from "smooth-scrollbar"

import "../css/styles.scss"

import ToggleManager from "../utils/toggle-manager"
import ArialTargets from "../utils/arial-targets"
import ModalTargets from "../utils/modal-targets"
import SvgIcons from "./svg-icons"

import Header from "./header/header"


//const Layout = ({ children }) => {
class Layout extends React.Component {
  componentDidMount() {
    ToggleManager();
    ArialTargets(); // EDDY: move this to just the pages that need it?
    ModalTargets();
    
    ScrollMagicPluginGsap(ScrollMagic, TweenLite, TimelineLite, gsap);

    class HorizontalScrollPlugin extends Scrollbar.ScrollbarPlugin {
      static pluginName = 'horizontalScroll';

      transformDelta(delta, fromEvent) {
        if (!/wheel/.test(fromEvent.type)) {
          return delta;
        }
        
        const { x, y } = delta; 

        return {
          y: 0,
          x: Math.abs(x) > Math.abs(y) ? x : y,
        };
      }
    }

    Scrollbar.use(HorizontalScrollPlugin);

    let scrollbar = undefined;

    if ( window.innerWidth >= 720 ) {
      scrollbar = Scrollbar.init(document.querySelector('#scrollbar'), {
        alwaysShowTracks: false,
        damping: 0.1
      });
    }

    window.addEventListener('resize', function(){
      if ( window.innerWidth < 720 ) {
        if ( Scrollbar.get(document.querySelector('#scrollbar')) === undefined ) {
        } else {
          Scrollbar.destroyAll()
        }
      } else {
        if ( Scrollbar.get(document.querySelector('#scrollbar')) === undefined ) {
          scrollbar = Scrollbar.init(document.querySelector('#scrollbar'), {
            alwaysShowTracks: false,
            damping: 0.1
          });
        }
      }
    });


    /*
    gsap.registerPlugin(Draggable);

    Draggable.create("#scrollit", {
      type: "scrollLeft",
      edgeResistance: 0.9,
      throwProps: !0,
      maxDuration: 1.2,
      minDuration: 1.2,
      lockAxis:true,
      throwProps:true,
      onPress: function() {
        console.log('E');
      },
      onDrag:function() {
        console.log('F')
      },
      onThrowUpdate : function() {
        console.log('G');
      },
      onRelease() {
        console.log('H')
      },
      snap: function(e) { 
        console.log('B');
      },
      onDragStart: function() {
        console.log('C');
      },
      onThrowComplete: function() { 
        console.log('D');
      }
    })
    */


    //let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    //console.log("is Chrome ? ", isChrome)

    let scenes = []
    let x = 0

    let controller = new ScrollMagic.Controller({
        refreshInterval: 0,
        vertical: false
    })

    window.addEventListener('resize', function(){
      if ( window.innerWidth < 780) {
        if (controller.enabled()) {
          controller.enabled(false);
        }
      } else if (!controller.enabled()) {
        controller.enabled(true);
      }

      controller.update(true);
    });


    //if ( isChrome ) {
      controller.scrollPos(function () {
        return x;
      });
    //}


    let el_scenes = document.querySelectorAll('main >.scrollmagic-scene');

    if ( el_scenes.length && window.innerWidth >= 780 ) {

      for (const el_scene of Array.from(el_scenes)) {
      //el_scenes.forEach((el_scene) => {
        var tl = gsap.timeline()

        let tweens = el_scene.querySelectorAll('[data-tween="1"]')

        let anims = [];

        for (const el of Array.from(tweens)) {
        //tweens.forEach((el) => {
          
          //if ( el.dataset.tweenfrom ) {
          if ( el.hasAttribute("data-tweenfrom") ) {

            if ( el.hasAttribute("data-xpercentoffset") ) {
              anims.push(
                gsap
                  .set(el, { x: el.getAttribute("data-xpercentoffset") })
              )
            }

            if ( el.hasAttribute("data-xoffset") ) {
              anims.push(
                gsap
                  .set(el, { x: el.getAttribute("data-xoffset") })
              )
            }

            if ( el.hasAttribute("data-xpercent") ) {
              anims.push(
                gsap
                  .from(el, 1, { xPercent: el.getAttribute("data-xpercent"), ease:Linear.easeNone }, 0)
              )
            }

            if ( el.hasAttribute("data-x") ) {
              anims.push(
                gsap
                  .from(el, 1, { x: el.getAttribute("data-x"), ease:Linear.easeNone }, 0)
              )
            }
          }

          /*
          if ( el.dataset.tweenfromto ) {
            console.log('tweenfromto');
            anims.push(gsap.to(el, 1, { xPercent: -100 }))
          }

          if ( el.dataset.tweenSet ) {
            //tl.add(gsap.set(el, 1, { xPercent: 80 }))
          }
          */

          
          
        }

        tl.add(anims);
        
        if ( tl ) {
          scenes.push(
            new ScrollMagic.Scene(
                { 
                  offset: 0,  
                  triggerElement: el_scene, 
                  triggerHook: 1,
                  duration: (window.innerWidth + el_scene.offsetWidth), 
                  reverse: true,
                }
              )
              .setTween(tl)
              .on("leave", function(e){
                //console.log('LEAVE scene' + el_scene.id);

                if ( el_scene.id === 'residences-scene-10' ) {
                  console.log(e.scrollDirection);
                  console.log('exit 10');
                }
              })
              .on("enter", function(e){
                //console.log('ENTER scene' + el_scene.id);

                if ( el_scene.id === 'residences-scene-10' ) {
                  console.log(e.scrollDirection);
                  console.log('enter 10');
                }
              })
              .on("progress", function(e){
                //console.log("progress => ", e.progress);
              })
              //.addIndicators()
              .addTo(controller)
          );
        }
        
      }

      /*
      if ( document.querySelector('.slider-floor .slick-dots') ) {
        console.log(-document.querySelector('#header').offsetWidth);

        scenes.push( 
          new ScrollMagic.Scene(
            {
              offset: -document.querySelector('#header').offsetWidth,
              triggerHook: 'onLeave',
              triggerElement: document.querySelector('#residences-scene-10'), 
              duration: document.querySelector('.slider-floor').offsetWidth,
            }
          )
          .on('enter', function(event) { console.log('enter');console.log(event.scrollDirection); })
          .on('leave', function(event) { console.log('leave');console.log(event.scrollDirection); })
          .addTo(controller) 
        );
      }
      */

      if ( Scrollbar.get(document.querySelector('#scrollbar')) !== undefined ) {
        scrollbar.addListener(function(status) {
          x = status.offset.x;
          
          //if ( isChrome ){
            controller.update();
          //} else {
          //  scenes.forEach(function(scene){
          //    scene.refresh();       
          //  });
          //}
        });
      }

    }     
  }

  render() {
    const { children } = this.props

    return (
      <>
        <SvgIcons />

        <TransitionPortal>

          <Header siteTitle="Flow Residences" />

          <div id="video-modal" className="modal">
            <div className="modal-bg" onClick={function(e) {e.currentTarget.closest('.modal').classList.remove('active')}} onTouchStart={function(e) {e.currentTarget.closest('.modal').classList.remove('active')}}></div>
            <div className="modal-container">
              <button className="modal-close" onClick={function(e) {e.currentTarget.closest('.modal').classList.remove('active')}} onTouchStart={function(e) {e.currentTarget.closest('.modal').classList.remove('active')}}><span></span>close</button>
              <div className="modal-content">
                <div className="video-frame"><div>
                  <iframe src="https://player.vimeo.com/video/432706438" title="Flow Residences" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div></div>
              </div>
            </div>
          </div>

        </TransitionPortal>

        <div id="scrollbar">
          <main id="scrollit">
            {children}
          </main>
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
