import React from "react"

import Form from "./form"


class RegisterPanel extends React.Component {
  render() {
    return (
      <div id="register-panel" className="header-register">
	      <button className="close-button svg--icon" data-toggle-close="register">
	        <svg><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close-button"></use></svg>
	      </button>

	      <div>
	        <h3>Register</h3>

	        <Form />

	        <p style={{textAlign: 'center', textTransform: 'uppercase'}}>
	          Jayde Pezet – <a href="tel:0402517365">0402 517 365</a><br />
	          Matthew Parkinson – <a href="tel:0412138096">0412 138 096</a>
	        </p>

	        <span className="sands-logo svg--icon">
	          <svg><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#sands-logo"></use></svg>
	        </span>
	      </div>
	    </div>
    )
  }
}

export default RegisterPanel