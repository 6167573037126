export default function ModalTargets() {
	let targets = document.querySelectorAll('[data-modal-target]');

	//targets.forEach((target) => {
	for (const target of Array.from(targets)) {
		target.addEventListener('click', (e) => {
			let currentTarget = '#' + target.dataset.modalTarget

			//targets.forEach((target) => {
			for (const target of Array.from(targets)) {
				if ( target !== document.querySelector(currentTarget) ) {
					target.classList.remove('active')
				}
			}

			document.querySelector(currentTarget).classList.toggle('active')
		});
	};

	/*
	These don't work because they get detacted after navigating to another page / component hotload. You should be using react state instead.

	document.querySelector('.modal .modal-close').addEventListener('click', (e) => {
		e.currentTarget.closest('.modal').classList.remove('active')
	});

	document.querySelector('.modal .modal-close').addEventListener('touchstart', (e) => {
		e.currentTarget.closest('.modal').classList.remove('active')
	});

	document.querySelector('.modal .modal-bg').addEventListener('click', (e) => {
		e.currentTarget.closest('.modal').classList.remove('active')
	});

	document.querySelector('.modal .modal-bg').addEventListener('touchstart', (e) => {
		e.currentTarget.closest('.modal').classList.remove('active')
	});
	*/
}